export default {
    de: {
        alprelax: "ALPrelax",
        header: {
            welcome: "Herzlich Willkommen bei",
            good_morning: "Guten Morgen",
            good_afternoon: "Schönen Nachmittag",
            good_evening: "Schönen Abend",
            bei: "bei",
            create: "Erstelle dein Konto",
            login: "Anmelden",
            favorites: "Favoriten",
            account: "Dein Konto",
            edit: "Bearbeiten",
            register: "Registrieren",
            userCreated: "Benutzer wurde erstellt",
            manageAbo: "Mitgliedschaft verwalten",
            selectAbo: "Wähle das Modell, das am besten zu dir passt!",
            privacy: "Datenschutz",
            payment: "Deine Zahlung",
            pw_reset: "Passwort zurücksetzen",
            faqs: "FAQs",
            a2hs: "ALPrelax App",
            loading: "Einen Moment bitte ...",
            about: "Über ALPrelax"
        },
        nav: {
            start: "Start",
            meditations: "Meditationen",
            favorits: "Favoriten",
            account: "Konto"
        },
        new_published: "Neu erschienen",
        recently_played: "Zuletzt angehört",
        heading_meditations: "Tiefe Entspannung für jeden Moment",
        heading_favorites: "Hier findest du deine Lieblingsmeditationen",
        heading_about: "Über ALPrelax",
        favorites_empty: "Du hast noch keine Favoriten ausgewählt.",
        pageNotFound: "Seite wurde nicht gefunden",
        userData: "Deine Benutzerdaten",
        yourPayments: "Deine Zahlungen",
        yourCurrentPayment: "Deine aktuelle Zahlung",
        payedDate: "Zahlung am",
        passwordSecurity: "Passwort & Sicherheit",
        faqs: "FAQs",
        FAQsText: "Wir haben hier ein paar nützliche Tipps für dich zusammengestellt.",
        goToFAQS: "Zu den FAQs",
        about: "Über ALPrelax",
        aboutText: "Ich bin Sophie, die Gründerin von ALPrelax. Meditation hat mein Leben bereichert ...",
        ctaInfoHeadline: "Gratis Meditationen",
        ctaInfoText: 'Mit deiner Mitgliedschaft "{abo}" hast du noch {freeSession} kostenlose Meditation(en) zur Verfügung.',
        ctaInfoTextNoMoreSessions: 'Mit deiner Mitgliedschaft "{abo}" hast du leider keine kostenlosen Meditationen mehr zur Verfügung.',
        button: {
            goback: "Zurück",
            close: "Schließen",
            register: "registrieren",
            login:"Anmelden",
            edit: "Bearbeiten",
            editProfile: "Profil bearbeiten",
            logout: "Ausloggen",
            save: "Speichern",
            cancel: "Abbrechen",
            backToLogin: "Zurück zum Login",
            sendLink: "Link senden", 
            resetPW: "Passwort zurücksetzen",
            reset: "Zurücksetzen",
            changeMembership: "Mitgliedschaft verwalten",
            selectAbo: "Mitgliedschaft wählen",
            upgradeNow: "Jetzt upgraden",
            upgradeAboNow: "Mitgliedschaft upgraden",
            releaseAllSessions: "Alle Meditationen freischalten",
            unsubscribeAbo: "Mitgliedschaft ändern",
            editAbo: "Mitgliedschaft ändern",
            deleteAccount: "Konto löschen",
            select: "wählen",
            selected: "ausgewählt",
            aboSuccessor: "Folgeabo",
            activ: "aktiv",
            moreInfos: "Finde hier das passende Modell für dich",
            payNow: "Jetzt bezahlen",
            confirm: "Bestätigen",
            continue: "Weiter",
            backToALPrelax: "Zurück zu ALPrelax",
            backToAccount: "Zurück zum Konto",
            showAll: "Alle anzeigen",
            showLess: "Weniger anzeigen",
            showMoreSettings: "Weitere Optionen anzeigen",
            hideMoreSettings: "Optionen verstecken",
            redeemCode: "Code einlösen",
            downloadInvoice: "Download Rechnung",
            subscribe: "abonnieren",
            unsubscribe: "abmelden",
            listenNow: "Jetzt anhören",
            cancelMembership: "Mitgliedschaft kündigen",
            redoCancelMembership: "Kündigung aufheben",
            redoChangeMembership: "Wechsel rückgängig machen",
            tippRead: "Hinweis schließen",
            more: "mehr ..."
        },
        form: {
            firstName: "Vorname",
            lastName: "Nachname",
            username: "Email",
            password: "Passwort",
            passwordRepeat: "Passwort wiederholen",
            oldPassword: "Altes Passwort",
            newPassword: "Neues Passwort",
            newPasswordRepeat: "Neues Passwort wiederholen",  
            iHaveARegistrationCode: "Ich habe einen Registrierungscode",
            iHaveACode: "Ich habe einen Code",
            enterCode: "Code eingeben",
            wantToRedeemCode: "Ich möchte einen Code einlösen",
            wantToJoinCompany: "Ich möchte meine Mitgliedschaft in eine Firmenmitgliedschaft umwandeln.",
            enterCompanyCode: "Firmencode eingeben"
        },
        formBilling: {
            street: "Straße",
            housenumber: "Nummer",
            zip: "Postleitzahl",
            city: "Stadt",
            country: "Land",
            taxIdentificationNumber: "Uid",
            iAmaCompany: "Firmenkunde"
        },
        formPassword: {
            password: 'Bitte bestätige die Änderung mit deinem Passwort'
        },
        billing: {
            header: "Deine Rechnungsdaten"
        },
        already_registered: "Du hast bereits ein Konto",
        login_now: "Hier anmelden",
        not_registered_yet: "Noch kein Konto erstellt",
        register_now: "Jetzt registrieren",
        pw_forgotten: "Passwort vergessen",
        pw_change: "Passwort ändern",
        pw_reset: "Passwort zurücksetzen",
        link_sent: "Wenn ein Benutzer mit dieser E-Mail gefunden wurde, wurde der Link gesendet.",
        editMode: "Bearbeiten",
        detailsMode: "Benutzerdaten",
        paymentMode: "Zahlungsstatus",
        paymentModeChecking: "Überprüfe Zahlungsstatus",
        paymentThankYou: "Vielen Dank!",
        paymentFailed: "Fehler bei der Zahlung!",
        paymentSuccessText: "Deine Zahlung wurde erfolgreich durchgeführt.",
        paymentFailedText: "Bei der Bearbeitung deiner Zahlung ist ein Fehler aufgetreten. Fehlercode:",
        selectAboMode: "Mitgliedschaft verwalten",
        abo:{
            current: "Aktuelle Mitgliedschaft",
            aboInfo: "Infos zur Mitgliedschaft",
            paymentInfo: "Zahlungsinfo",
            activeSince: "Aktiv seit",
            lastPayment: "Letzte Zahlung",
            noPayment: "keine Zahlungen bisher",
            activeUntil: "Aktiv bis",
            autoReneval: "Wird automatisch verlängert am:",
            autoChange: 'Wird automatisch umgestellt auf "{aboSuccessor}" am:',
            canceledBy: "wird gekündigt am:",
            canceledOn: "wurde gekündigt zum:",
            joinCompanyOn: "Firmenmitgliedschaft ab",
            cancelPossibleTill: "Kündigung möglich bis:",
            canceledHeadline: "Kündigung",
            canceledMessage: "Du hast deine Mitgliedschaft mit {cancelByDate} zur Kündigung vorgemerkt.",
            nextPayment: "Nächste Zahlung",
            once: "einmalig",
            month: "Monat",
            year: "Jahr",
            quarter: "Vierteljahr",
            selectPaymentMethode: "Wähle deine bevorzugte Zahlung",
            selectedAbo: "Ausgewähltes Abo",
            changed: "Mitgliedschaft wurde geändert",
            changedText:'Das Modell für deine Mitgliedschaft wurde erfolgreich zu "{abo}" geändert.',
            changedPlanedText: 'Deine Mitgliedschaft wird nach Ablauf des aktuellen Bezahlzeitraumes, am: {expirationDate} zu "{abo}" geändert.',
            canceledText:'Deine Mitgliedschaft wurde gekündigt zum: {expirationDate} (zum Ablauf deines schon bezahlten Zeitraumes).',
            redoCanceledText:'Die Kündigung deiner Mitgliedschaft wurde rückgängig gemacht.',
            redoChangedText:'Die Änderung deiner Mitgliedschaft wurde rückgängig gemacht.',
            here: 'Hier',
            hereText: "kommst du zurück zu ALPrelax - Deine Wohlfühlapp",
            specialAbo: "Sonderangebot",
            available_until: "Verfügbar bis",
            newsletter: "Newsletter",
            newsletter_subscribed: "abonniert",
            newsletter_notsubscribed: "nicht abonniert",
            paymentOnChanging: "Die Umstellung auf die gewählte Mitgliedschaft wird mit Ablauf der aktuellen Mitgliedschaft durchgeführt. Du hast bereits eine Zahlung an uns geleistet, daher wird die Bezahlung der neuen Mitgliedschaft am {expirationDate} automatisch durchgeführt.",
            changeAbo: "Umstellung deiner Mitgliedschaft",
            changeAboMessage: "Die gewünschte Umstellung deiner Mitgliedschaft wird mit Ablauf der aktuellen am: {expirationDate} automatisch durchgeführt.",
            pricesIncludeTax: "Alle Preise inkl. USt",
            willJoinCompany: "Die Umstellung auf die gewählte Firmen-Mitgliedschaft wird mit Ablauf der aktuellen Mitgliedschaft durchgeführt.",
            joinedCompany: "Deine Mitgliedschaft wurde in eine Firmen-Mitgliedschaft umgewandelt."
        },
        loading: "Einen Moment bitte ...",
        submitMail: "Bitte bestätige nun die E-Mail, die wir dir geschickt haben, um dein Profil zu aktivieren.",
        submitMailTipp: "Kleiner Tipp: Schau auch im Spam Ordner nach.",
        registrationFinished: "Registrierung abgeschlossen",
        regLoginNow: "Deine Registrierung wurde erfolgreich abgeschlossen. Du kannst dich jetzt",
        pwResetFinished: "Passwort wurde zurückgesetzt",
        pwChangeSuccess: "Deine Password wurde erfolgreich geändert. Du kannst dich jetzt",
        pageNotFoundText: "Diese Seite wurde leider nicht gefunden. Gehe ",
        newsletterCheck: "Ich möchte den Newsletter mit Infos zu ALPrelax abonnieren. Ich stimme dabei zu, dass meine personenbezogenen Daten genutzt werden, um diese werblichen E-Mails zu erhalten und weiß, dass ich den Newsletter jederzeit widerrufen kann.",
        toLogin: 'zum Login',
        here: "hier",
        login: "einloggen",
        noRecent: "Du hast noch keine Meditationen angehört.", 
        noNewReleased: "Es wurden keine neu erschienenen Medititationen gefunden.",
        findMeditations: "Gehe zu allen Meditationen",
        toFindMeditations: "",
        linkSent: "Link wurde gesendet, wenn ein Benutzerkonto mit dieser E-Mail gefunden wurde.",
        alreadyConfirmed: "Registrierung wurde bereits bestätigt.",
        redirectToLogin: "Sie werden in {time} Sekunde/n automatisch zum Login weitergeleitet.",
        paymentPending: "Zahlung erfolgt",
        addToHomeScreenSubline: "zu deinem Startbildschirm hinzufügen",
        info:{
            no_free_sessions_text: "Dir gefällt ALPrelax und du möchtest auch die anderen Meditationen anhören? Dann erweitere jetzt deine Mitgliedschaft und du hast vollen Zugriff auf alle Meditationen."
        },
        duration:{
            title: "Dauer",
            less_ten: " <10 min",
            ten_to_fifteen: "10-15 min",
            more_15: "> 15min",
            all: "Alle"
        },
        error:{
            required: "Eingabe erforderlich",
            pw_too_short: "Passwort zu kurz",
            too_short: "Mind. 3 Zeichen erforderlich",
            wrongMail: "Ungültige Email",
            dontMatch: "Passwörter stimmen nicht überein",
            privacyCheck: "Bitte akzeptiere die Datenschutzbestimmungen um mit der Registrierung fortzufahren.",
            termsCheck: "Bitte akzeptiere die Allgemeinen Geschäftsbedingungen um mit der Registrierung fortzufahren.",
            error: "Es ist ein Fehler aufgetreten.",
            username_exists: 'Benutzer mit dieser Email existiert bereits.',
            login:{
                failed: "E-Mail oder Passwort wurde falsch eingegeben.",
                incorrect: "Benutzer ist nicht eingeloggt.",
            },
            passwordInvalid: "Passwort ändern fehlgeschlagen.",
            changesFailedInvalidPassword: "Die Änderungen konnten nicht durchgeführt werden! Falsches Passwor!",
            serverInvalidPassword: "Falsches Passwort",
            userNotFound: 'Benutzer wurde nicht gefunden.',
            no_valid_hash: 'Bestätigung nicht möglich. (Hash abgelaufen)',
            hash_invalid: "Bestätigung nicht möglich. (Falscher Hash)",
            no_valid_sessionId: "Favorit hinzufügen fehlgeschlagen.",
            no_valid_uid: "Favorit entfernen fehlgeschlagen.",
            logoutFailed: "Logout fehlgeschlagen.",
            user_already_enabled: "Email wurde bereits bestätigt.",
            abo_expired: "Mitgliedschaft abgelaufen.",
            no_free_sessions_available: "Keine kostenlose Meditation mehr verfügbar.",
            no_valid_abo: 'Dieses Abo existiert nicht.',
            deleteFailed: 'Löschen fehlgeschlagen.',
            delete_not_possible: 'Löschen nicht möglich.',
            statistic_not_found: 'Es wurde ',
            registrycode_not_found: 'Der aufgerufene Link bzw. der eingegebene Code ist ungültig. Dein Konto konnte nicht erstellt werden.',
            abocode_not_found: 'Der aufgerufene Link bzw. der eingegebene Code ist ungültig. Dein Konto konnte nicht erstellt werden.'
        },
        snackbar: {
            userSaved: "Änderungen wurden gespeichert.",
            pwSaved: "Passwort wurde geändert.",
            favoritAdded: "Favorit wurde hinzugefügt.",
            favoritRemoved: "Favorit wurde entfernt.",
            addToHomeScreen: "Lege die ALPrelax App auf deinen Homescreen.",
        },
        dialog:{
            no_free_sessions_available: `Keine kostenlose Meditationen mehr verfügbar.`,
            dear: "Liebe/r {name}, ",
            no_free_sessions_text: "du hast jetzt schon zwei kostenlose Meditationen angehört. Diese kannst du auch weiterhin beliebig oft anhören. Um weitere Meditationen anhören zu können, kannst du jetzt deine Mitgliedschaft erweitern.",
            dontShowAgain: "Fenster nicht mehr anzeigen.",
            confirmDeleteTitle: 'Bist du dir sicher?',
            confirmDeleteText: 'Willst du dein Konto wirklich unwiderruflich löschen?',
            confirmUserDeleteAboText01: 'Du hast noch eine aktive Mitgliedschaft, dieses läuft erst am: {expirationDate} aus.',
            confirmUserDeleteAboText02: 'Durch die sofortige Kündigung deines Kontos wird auch deine bestehende Mitgliedschaft unwiderruflich gelöscht.',
            confirmUserDeleteAboText03: 'Eine Gutschrift bzw. Rückerstattung deiner geleisteten Zahlungen wird nicht durchgeführt!',
            areYourSure: 'Bist du dir sicher?',
            confirmManagFreeAboText: "möchtest du deine Mitgliedschaft wirklich auf kostenlos ändern? Du hast somit keinen Zugriff mehr auf alle Meditationen.",
            confirmCancelAbo: "deine Mitgliedschaft wird mit Ende deines Bezahlungszeitraumes, am {expirationDate} gekündigt und in eine kostenlose Mitgliedschaft umgewandelt. Du hast somit keinen Zugriff mehr auf alle Meditationen.",
            confirmCancelAboQuestion: "Möchtest du wirklich kündigen?",
            confirmAutoPaymentTtitle: "Automatische Zahlung",
            confirmAutoPaymentText: "Ich autorisiere ALPrelax, diese Zahlungsmethode zu speichern und diese für zukünftige Zahlungen der gewählten, kostenpflichtigen Mitgliedschaft zu verwenden."
        },
        status:{
            prepared: "Vorbereitet",
            pending: "Unvollständig",
            success: "Bezahlt",
            canceled: "Abgebrochen",
            unknown: "Status unbekannt"
        }
    }
}